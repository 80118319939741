import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ImpressumView from "@/views/ImpressumView.vue";
import DatenschutzView from "@/views/DatenschutzView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenschutzView
  },
  {
    path: '/discord',
    component: HomeView,
    beforeEnter() {
      window.location.replace("https://discord.gg/S8PTcwbxWQ")
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
