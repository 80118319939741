<script setup lang="ts">

</script>

<template>
<div class="cube-welcome">
  <div class="cube-welcome__text">
    <h1>Willkommen auf Cube Roleplay</h1>
    <p>
      Wir freuen uns, dass du Teil unserer aufstrebenden Community werden möchtest! Cube RP ist ein noch junges GTA 5 Roleplay-Projekt auf der Alt:V-Plattform, das von einer kleinen Gruppe enthusiastischer Spieler entwickelt wird.<br>
      <br>
      Hier kannst du dich mit anderen Spielern austauschen, über das Projekt diskutieren und schon bald in die spannende Welt von Cube RP eintauchen.<br>
    </p>
    <h2>Was erwartet dich?</h2>
    <p>
      <strong>Eine wachsende Community:</strong> Triff neue Leute und finde Gleichgesinnte.<br>
      <strong>Spannendes Roleplay:</strong> Erlebe authentische Rollenspiele in einer dynamischen Umgebung.<br>
      <strong>Regelmäßige Updates:</strong> Wir arbeiten ständig daran, Cube RP noch besser zu machen.<br>
      <br>
    </p>
    <h2>Stay tuned</h2>
    <p>
      Bleib auf dem Laufenden und sei dabei, wenn wir Cube RP gemeinsam gestalten! Tritt unserem <a href="https://discord.gg/S8PTcwbxWQ">Discord</a> bei.<br>
      Auf unserem Discord bekommst du immer die neusten Informationen zum Entwicklungsstand des Servers und kannst dich als Tester Registrieren.
    </p>
    <h2>Cube Tester</h2>
    <p>
      Wenn du ein Interesse an der Entwicklung des Server hast kannst du uns auch unterstützen ohne mitglied des Teams zu sein.<br>
      Dazu kannst du dich auf unserem Discord als Tester eintragen lassen.<br>
      Wir wollen regelmäßig gewisse dinge testen um den besten Qualitätsstandard zu gewährleisten.
    </p>
    <h2>Cube Team</h2>
    <p>
      Solltest du weitere kenntnisse und interessen haben kannst du dich auf unserem Discord auch gerne als teil des Teams bewerben und uns an vorderster front unterstüten.<br>
      Entwickler sowie Modder und Gamedesigner werden immer gesucht. Jedoch ist auch der Suporter sehr wichtig und wird immer gebraucht.<br>
      Hast du also interesse von anfang an dabei zu sein Bewirb dich doch einfach!
    </p>
  </div>
</div>
</template>

<style lang="scss">
.cube-welcome{
  margin: 15px;
  display: flex;

  &__image{
    height: 225px;
    width: 480px;
    background-color: #2c3e50;
  }
}
</style>