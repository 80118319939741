<script setup lang="ts">

</script>

<template>
<div class="cube-footer">
  <router-link to="impressum">Impressum</router-link>
  <router-link to="datenschutz">Datenschutz</router-link>
  <a href="https://discord.gg/S8PTcwbxWQ">Discord</a>
</div>
</template>

<style lang="scss">
.cube-footer{
  border-top: 3px solid #aa2a2a;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1vw;
}
</style>