<script setup lang="ts">

</script>

<template>
<h1>Datenschutz</h1>
</template>

<style scoped lang="scss">

</style>