<script setup lang="ts">

</script>

<template>
<div class="cube-landing">

</div>
</template>

<style lang="scss">
.cube-landing{
  background: url("@/assets/background.gif");
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

  border-bottom: 3px solid #aa2a2a;
}
</style>