<script setup lang="ts">

</script>

<template>
  <h1>Impressum</h1>
</template>

<style scoped lang="scss">

</style>